import { get, post, put, remove } from '@/services/api.service'

const API_URL = '/pages'

class PagesService {
  getAllPosts(){
    return get(`${API_URL}?extended=1`)
      .then(res => res)
  }
  getById({ id }) {
    return get(`${API_URL}/${id}?extended=1`)
      .then(res => res)
  }
  create({ item }) {
    return post(`${API_URL}`, item)
    .then(res => res)
  }
  edit({ item }) {
    return put(`${API_URL}/${item.id}`, item)
    .then(res => res)
  }
  remove(id) {
    return remove(`${API_URL}/${id}`)
      .then(res => res)
  }
}

export default new PagesService()