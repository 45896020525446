<script>
import pagesService from '@/services/pages.service'
import libraryService from '@/services/library.service'
import Page from '@/data/models/Page'
import EditLayout from '@/layout/EditLayout'
import indexing from '@/data/enums/seoIndexing.enum'
import formTypes from '@/data/arrays/formTypes.array'

export default {
  name: 'SinglePost',
  components: {
    EditLayout
  },
	data() {
		return {
      item: new Page,
      photo: null,
      indexing: indexing,
      formTypes: formTypes,
		}
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    isNew() {
      return this.id === 'new'
    },
  },
	mounted() {
    !this.isNew && this.id && this.get()
	},
	methods: {
    get(type = null) {
      pagesService.getById({ id: this.id })
      .then(res => {
        this.item = res.data
        res.data.library_id && this._getPhoto({ id: res.data.library_id })
      })
    },
    _getPhoto({ id }) {
      libraryService.getById({ id })
        .then(res => {
          this.photo = res.data
        })
    },
    create() {
      pagesService.create({ item: this.item })
        .then(_res => {
          this.$notify({
            message: 'Page successfully added',
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
          this.$router.push({ name: 'pages' })
        })
    },
    edit() {
      pagesService.edit({ item: this.item })
        .then(_res => {
          this.$notify({
            message: 'Page successfully saved',
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
        })
    },
	}
}
</script>

<template>
  <EditLayout 
    supports-photo
    :item="item"
    :submit-button-text="isNew ? $t('create') : $t('save')"
    :photo="photo"
    @submit="isNew ? create() : edit()"
  >
    <div slot="sidebar-bottom">
      <div class="formType form-group">
        <label>{{ $t('formType') }}</label>
        <select
          v-model="item.form_type"
          class="form-control"
        >
          <option :value="null">
            {{ $t('none') }}
          </option>
          <option
            v-for="formType in formTypes"
            :key="formType"
            :value="formType"
          >
            {{ formType }}
          </option>
        </select>
      </div>
      <div class="metaIndexling form-group">
        <label>{{ $t('metaIndexing') }}</label>
        <select
          v-model="item.seo_indexing"
          class="form-control"
        >
          <option :value="null">
            {{ $t('indexing') }}
          </option>
          <option
            v-for="index in indexing"
            :key="index"
            :value="index"
          >
            {{ index }}
          </option>
        </select>
      </div>
    </div>
  </EditLayout>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

</style>
