/* eslint-disable camelcase */
export default class Page {
  alias;
  description;
  description_socials;
  library_id;
  name;
  published;
  seo_indexing;
  seo_title;
  type;
  form_type;

  constructor() {
    this.alias = "";
    this.description = "";
    this.description_socials = "";
    this.library_id = null;
    this.name = "";
    this.published = 0;
    this.seo_indexing = null;
    this.seo_title = "";
    this.type = "page";
    this.form_type = null;
  }
}
